<template>
  <b-container>
    <div class="prompt-content">
      <div
        class="test-prompt"
        v-if="$route.query.state == 0 && promptState === 0"
      >
        <b-row>
          <b-col class="prompt-title">英语模拟考试说明</b-col>
        </b-row>
        <b-row>
          <b-col class="text-left"
            >本试题分选择题部分和非选择题部分，满分为150分，考试时间约为120分钟。答卷前，请仔细阅读机器模拟考试规则。</b-col
          >
        </b-row>
        <b-row>
          <b-col cols="12" class="text-left prompt-title">机器考试说明</b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-left"
            cols="12"
            v-for="(item, index) in textList"
            :key="index"
            >{{ item }}</b-col
          >
        </b-row>
        <b-row class="button-item">
          <b-col>
            <b-button
              class="begin-button"
              @click="
                () => {
                  promptState = 1;
                }
              "
            >
              <span class="iconfont icondatiqia"></span>
              <span>正式答题</span>
            </b-button>
            <span @click="clickBegin" >
              <a class="back-button">回训练基地</a>
            </span>
          </b-col>
        </b-row>
      </div>
      <div class="listening-prompt" v-if="promptState === 1">
        <b-row class="button-item">
          <b-col cols="12" class="listening-buttons">
            <div class="start-test-audition">
              <div class="">
                <b-button
                  @click="toListening"
                  class="begin-button start-listening"
                >
                  <span class="iconfont iconerji"></span>
                  <span>开始听力测试</span>
                </b-button>
              </div>

              <div class="listening-test">
                <span
                  v-if="!voiceState"
                  class="iconfont icon-bofang1"
                  @click="startVoice"
                >
                </span>
                <span
                  v-if="voiceState"
                  class="iconfont icon-zanting"
                  @click="stopVoice"
                ></span>
                <span>试音测试</span>
              </div>
            </div>
          </b-col>
          <b-col class="hearing-text"
            >你已开始正式答题，请确保音频设备和耳机工作正常，点击“开始听力测评”进入听力测试环节。</b-col
          >
        </b-row>
      </div>
      <div class="noListening-prompt" v-if="$route.query.state == 1">
        <b-row class="button-item">
          <b-col cols="12">
            <b-button class="begin-button" @click="toMock()">
              <span>继续答题</span>
              <span class="iconfont iconbofang"></span>
            </b-button>
          </b-col>
          <b-col class="hearing-text">
            你已完成中考模拟考试听力测试部分，请点击“继续答题”，进入非听力部分的测试。
          </b-col>
        </b-row>
      </div>
    </div>
    <video
      v-show="false"
      id="mockVedio"
      class="azuremediaplayer amp-default-skin amp-big-play-centered"
    ></video>
  </b-container>
</template>

<script>
// import { getListeningExamId } from "@/api/englishMock.js";
var myPlayer;
export default {
  data() {
    return {
      promptState: 0,
      listeningExamId: "",
      textList: [
        "1、点击正式答题后，系统将先开启独立的听力测试，再进行非听力测试。两部分测试均可提前提交答案，也可待系统到时自动提交。",
        "2、学生进入非听力部分的测试后，系统将开启110分钟倒计时，到时如未提交试卷则系统将自动提交。",
        "3、第VIII部分为写作题，分为情景运用和作文两部分，均需学生对照标准答案和范文，进行在线自我评分；自评完成后方能提交整份试卷。",
      ],
      voiceState: false,
    };
  },
  mounted() {
    // if(this.$route.query.state == 0){
    //     getListeningExamId({examId:this.$route.query.id}).then(res=>{
    //         this.listeningExamId = res.result.listeningExamId
    //     })
    // }
    this.voiceInit();
  },
  methods: {
    clickBegin() {
      debugger
      if (this.$route.query.isPass) {
        this.$router.replace({
          name: "MockBegin",
          query: {
            isPass: this.$route.query.isPass,
          },
        });
      } else {
        this.$router.replace({
          name: "MockBegin",
        });
      }
    },
    startVoice() {
      this.voiceState = true;
      myPlayer.play();
    },
    stopVoice() {
      this.voiceState = false;
      myPlayer.pause();
    },
    voiceInit() {
      var myOptions = {
        nativeControlsForTouch: false,
        controls: true,
        autoplay: false,
      };
      myPlayer = window.amp("mockVedio", myOptions);
      myPlayer.src([
        {
          src: "https://eduus-cnn21.streaming.media.chinacloudapi.cn/21b53d9b-eae8-4c36-b763-086c01bb5ac8/English济南市初三英语大模拟听力试音.ism/manifest",
          type: "application/vnd.ms-sstr+xml",
        },
      ]);
      let self = this;
      myPlayer.addEventListener("ended", function () {
        self.voiceState = false;
      });
    },
    toListening() {
      this.stopVoice();

      this.$router.replace({
        name: "MockListening",
        query: {
          id: this.$route.query.id,
          isPass: this.$route.query.isPass,
        },
      });
    },
    toMock() {
      this.$router.replace({
        name: "MockTest",
        query: {
          examId: this.$route.query.id,
          examname: this.$route.query.examname,
          isPass: this.$route.query.isPass,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt-content {
  min-height: 30vw;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 4rem 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
  letter-spacing: 1px;
}
.text-left {
  text-align: left;
  font-size: 16px;
  padding-left: 2rem;
  text-indent: -2rem;
}
.prompt-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem 2rem 1.5rem;
}
.button-item {
  margin: 3rem 0 0;
}
.start-listening {
  margin: auto;
}
.begin-button {
  background-image: linear-gradient(#fbab43, #fbb860);
  border: #fbb860;
  font-size: 1.1rem;
  font-weight: bold;
  .iconbofang {
    font-size: 13px;
    padding-left: 7px;
  }
  .iconerji {
    font-size: 20px;
    padding-right: 7px;
  }
  .icondatiqia {
    font-size: 18px;
    padding-right: 4px;
  }
}
.back-button {
  margin: 0 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: #000 !important;
  text-decoration: underline;
}
.hearing-text {
  padding: 3rem 0 0;
}
.icon-bofang1,
.icon-zanting {
  color: #fbb860;
  font-size: 2rem;
  cursor: pointer;
}
.start-test-audition {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    padding: 0rem 1rem;
  }
  .listening-test {
    display: flex;
    align-items: center;
  }
}
</style>
